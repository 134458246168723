import { useCopy, usePathData, usePropsObject } from "@/lib/contexts/appContext"
import { templateMapping } from "@/lib/templateMapping"
import NotFound from "@/components/base/notFound"

import Popup from "./popup/popup"
import Header from "./header/header"
import Footer from "./footer/footer"
import Sidebar from "@/components/blocks/theme/campaign-sidebar/sidebar"

export function Layout() {
  const { theme, sidebarContent } = usePropsObject()
  const pathData = usePathData()
  const copy = useCopy()

  const Template = templateMapping[pathData.template] ?? NotFound
  const hideFooter = theme?.hideFooter ?? false
  const hasSidebar = pathData.template === "campaign" && Boolean(sidebarContent)

  return (
    <>
      <Popup data={copy?.general?.popUp} />
      <Header hasSidebar={hasSidebar} />
      <Template />
      {!hideFooter && <Footer hasSidebar={hasSidebar} />}
      {hasSidebar && <Sidebar block={sidebarContent} />}
    </>
  )
}
